import {
  createStore,
  createLogger
} from 'vuex'
import robot from './robot'
import game from './game'
import user from "./user";
import quizzes from "./quizzes";
import ai from "./ai";
import swaInfo from "./swaInfo";

import common from '@/store/modules/common';
// import global
import global from '@/store/global';
const debug = process.env.NODE_ENV !== 'production'
import VuexPersistence from 'vuex-persist'
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user', 'ai', 'swaInfo',]
})
export default createStore({
  modules: {
    robot,
    common,
    game,
    user,
    global,
    quizzes,
    ai,
    swaInfo,
  },
  strict: debug,
  plugins: debug ? [createLogger(), vuexLocal.plugin] : [vuexLocal.plugin]
})
