import ApiService from "@/assets/js/api.service";
import {
  PLAYERS
} from "@/store/game";
import {useStore} from "vuex";
import {LOADING} from "@/store/global";
import {USER_INFO} from "@/store/user";

export const generateAiImage = "generateAiImage";
export const generateAiText = "generateAiText";
export const aiImageUrl = "aiImageUrl";
export const aiText = "aiText";
export const chatBotPopUp = "chatBotPopUp";
export const nadAvatarName = "nadAvatarName";
export const greenAppleAvatarName = "greenAppleAvatarName";
export const isStopAnimation = "isStopAnimation";
/*
  3D아바타이름   ==  실제 사람 이름
  Armature    ==  hyunbin
  Armature001 ==  yeonwoo
  Armature002 ==  leeyoon
  Armature003 ==  jinhyuk
  Armature004 ==  siyoon
*/

const state = {
  [aiImageUrl]: null,
  [aiText]: null,
  [chatBotPopUp]: {
    isChatBotPopUp: false,
  },
  [nadAvatarName]: null,
  [greenAppleAvatarName]: null,
  [isStopAnimation]: false,
}
const getters = {
  [aiImageUrl](state) {
    return state[aiImageUrl];
  },
  [aiText](state) {
    return state[aiText];
  },
  [chatBotPopUp](state) {
    return state[chatBotPopUp];
  },
  [nadAvatarName](state) {
    return state[nadAvatarName];
  },
  [greenAppleAvatarName](state) {
    return state[greenAppleAvatarName];
  },
  [isStopAnimation](state) {
    return state[isStopAnimation];
  },
}
const mutations = {
  [aiImageUrl](state, data) {
    state[aiImageUrl] = data;
  },
  [aiText](state, data) {
    state[aiText] = data;
  },
  [chatBotPopUp](state, data) {
    state[chatBotPopUp] = data;
  },
  [nadAvatarName](state, data) {
    state[nadAvatarName] = data;
  },
  [isStopAnimation](state, data) {
    state[isStopAnimation] = data;
  },
}

const actions = {

  /**
   *
   * @param context
   * @param params {prompt: string}
   * @returns {Promise<void>}
   */
  async [generateAiImage](context, params) {

    // ! json 의 경우 data 만 보냄
    const response = await fetch('https://hypherverse.com:31000/generate-image', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({prompt: params.prompt}),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();

    // ! 이미지 url 에서 www. 제거
    data.imageUrl = data.imageUrl.replace('www.', '')

    context.commit(aiImageUrl, data.imageUrl);
  },
  async [generateAiText](context, params) {

    // ! json 의 경우 data 만 보냄
    const response = await fetch('https://hypherverse.com:31000/generate-text', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({prompt: params.prompt}),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data.result);

    context.commit(aiText, data.result);

    return data.result;
  },
  [chatBotPopUp](context, data) {
    context.commit(chatBotPopUp, data);
  },
  [nadAvatarName](context, data) {
    context.commit(nadAvatarName, data);
  },
  [greenAppleAvatarName](context, data) {
    context.commit(greenAppleAvatarName, data);
  },
  [isStopAnimation](context, data) {
    context.commit(isStopAnimation, data);
  },
  [aiImageUrl](context, data) {
    context.commit(aiImageUrl, data);
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
