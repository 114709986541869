////////////////////////////////
///////// actions
import {
  LOADING
} from "@/store/global";

export const USER_INFO = "userInfo";
export const USER_AUTH = "userAuth";
export const USER_AVATAR = "userAvatar";
export const ROOM_NAME = "roomName";
export const AUTH_CHECK = 'authCheck';


// initial state
const state = () => ({
  //
  /* NFT_IMAGE: "https://asomemeta-bucket.s3.ap-northeast-2.amazonaws.com/8ce82ba2-0426-49ee-8383-90515970a601.jpg"
  NFT_JSON: "https://asomemeta-bucket.s3.ap-northeast-2.amazonaws.com/e45c6070-44ae-4439-a6c4-cdb388160045.json"
  PASSWORD: "qq"
  ROBOT_IMAGE: "https://asomemeta-bucket.s3.ap-northeast-2.amazonaws.com/16d9d24c-1748-4968-937f-e7d1bd98a27a.jpg"
  SIGN_DATA: null
  TOKEN: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0ZXN0IjoiMTIzNCIsImlhdCI6MTY2NzgxNDEzMCwiZXhwIjoxNjY3ODg2MTMwfQ.tEbZirjmjMadbD0gPLSsQKYKNy3twbN0njietsFdAO8"
  TRANSACTION_RECEIPT
  UQ_VALUE: null
  USER_ID: "qq"
  USER_LEVEL: 1
  USER_NAME: "hi"
  USER_UUID: */
  [USER_INFO]: {}, // userId
  [USER_AUTH]: {}, // userAuth
  [USER_AVATAR]: {}, // userAvatar
  [ROOM_NAME]: {}, // roomName
  [AUTH_CHECK]: {
    isCheck: true,
  }, // roomName
})

// getters
const getters = {

  [USER_INFO](state) {
    return state[USER_INFO];
  },
  [USER_AUTH](state) {
    return state[USER_AUTH];
  },
  [USER_AVATAR](state) {
    return state[USER_AVATAR];
  },
  [ROOM_NAME](state) {
    return state[ROOM_NAME];
  },
  [AUTH_CHECK](state) {
    return state[AUTH_CHECK];
  },
}

// actions
const actions = {
  [USER_INFO](context, payload) {
    context.commit(USER_INFO, payload);
  },
  [USER_AUTH](context, payload) {
    context.commit(USER_AUTH, payload);
  },
  [USER_AVATAR](context, payload) {
    context.commit(USER_AVATAR, payload);
  },
  [ROOM_NAME](context, payload) {
    context.commit(ROOM_NAME, payload);
  },
  [AUTH_CHECK](context, payload) {
    context.commit(AUTH_CHECK, payload);
  },
}

// mutations
const mutations = {
  reset: () => {},
  [USER_INFO](state, payload) {
    state[USER_INFO] = payload;
  },
  [USER_AUTH](state, payload) {
    state[USER_AUTH] = payload;
  },
  [USER_AVATAR](state, payload) {
    state[USER_AVATAR] = payload;
  },
  [ROOM_NAME](state, payload) {
    state[ROOM_NAME] = payload;
  },
  [AUTH_CHECK](state, payload) {
    state[AUTH_CHECK] = payload;
  },
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
