// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

import {
  createApp
} from "vue";
import App from "./App.vue";
// import './registerServiceWorker';
import router from "./router";
import "./assets/scss/main.scss";
// import "bootstrap/dist/js/bootstrap";
// import { ModalPlugin } from 'bootstrap-vue'
import { useCookies } from 'vue3-cookies';

// import * as VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n';

/*import ko from '../i18n/ko.ts';
import en from '../i18n/en.ts';
import zh from '../i18n/zh.ts';*/
import ko from '../i18n/ko.json';
import en from '../i18n/en.json';
const messages = {
  ko,
  en,
}

/* import the fontawesome core */
import {
  library
} from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

/* import v-click-outside */
import vClickOutside from 'click-outside-vue3'

/* import specific icons */
import {
  fas
} from '@fortawesome/free-solid-svg-icons'
import {
  far
} from '@fortawesome/free-regular-svg-icons'
import {
  fab
} from '@fortawesome/free-brands-svg-icons'
library.add(fas, far, fab)
// import vue
// lottie
import ApiService from "@/assets/js/api.service";

import LottieAnimation from "lottie-web-vue";
import store from './store'
import mitt from 'mitt'
// import ManagerAudio from "./util/ManagerAudio";

// google social login
import vue3GoogleLogin from 'vue3-google-login'

// kakao social login
import { createVueKakaoSdk } from 'vue3-kakao-sdk'

// debugger
// ManagerAudio.init();

import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import VueMathjax from 'vue-mathjax-next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import './registerServiceWorker'

// mobile check
import Vue3MobileDetection from "vue3-mobile-detection";

// Sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// vue-gtm
import { createGtm } from '@gtm-support/vue-gtm';

const emitter = mitt();

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

const app = createApp(App);
ApiService.init(app, store);
app.config.globalProperties.emitter = emitter
app.use(router);
app.use(LottieAnimation);
app.use(store);
app.use(mitt);
app.use(vClickOutside);
app.use(useCookies);
app.use(Vue3MobileDetection);
app.use(VueSweetalert2, options);
app.use(
    createGtm({
      id: 'GTM-W7XM6WBW', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects
      // [{id: 'GTM-xxxxxx',
      // queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}},
      // {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}],
      // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      queryParams: {
        // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
        gtm_preview: 'env-4',
        gtm_cookies_win: 'x',
      },
      // source: 'https://customurl.com/gtm.js', // Add your own serverside GTM script
      source: 'https://www.googletagmanager.com/gtm.js?id=GTM-W7XM6WBW', // Add your own serverside GTM script
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      nonce: '2726c7f26c', // Will add `nonce` to the script tag
      enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
      trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    }),
);
const i18n = createI18n({
  legacy: false, // Vue 3 모드
  locale: navigator.language || 'en', // $cookies 대신 navigator.language 사용
  // messages: { en, ko, zh },
  messages: messages,
});

app.use(i18n);

// app.use(ModalPlugin);
app.use(vue3GoogleLogin, {
  clientId: '167763561047-b9vhikngn94cmamgeuq983lgsoabjqe8.apps.googleusercontent.com'
})

app.use(createVueKakaoSdk('5243bd5d6e8b71cec7291636257d0448'))
app.use(VueMathjax);
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('pulseLoader', PulseLoader)
app.mount("#app");
