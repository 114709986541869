////////////////////////////////
///////// actions
export const PLAYERS = "PLAYERS";
export const COUNT_TIME = "COUNT_TIME";
export const GAME_SCORE = "GAME_SCORE";
// initial state
const state = () => ({
  [PLAYERS]: [],
  [COUNT_TIME]: {}, // {currentTime}
  [GAME_SCORE]: {
    totalScore: 0
  },
})

// getters
const getters = {

  [PLAYERS](state) {
    return state[PLAYERS];
  },
  [COUNT_TIME](state) {
    return state[COUNT_TIME];
  },
  [GAME_SCORE](state) {
    return state[GAME_SCORE];
  },
}

// actions
const actions = {

  [PLAYERS](context, payload) {
    context.commit(PLAYERS, payload);
  },
  [COUNT_TIME](context, payload) {
    context.commit(COUNT_TIME, payload);
  },
  [GAME_SCORE](context, payload) {
    context.commit(GAME_SCORE, payload);
  }

}

// mutations
const mutations = {
  reset: () => {},

  [PLAYERS](state, payload) {
    state[PLAYERS] = payload;

  },
  [COUNT_TIME](state, payload) {
    state[COUNT_TIME] = payload;

  },
  [GAME_SCORE](state, payload) {
    state[GAME_SCORE] = payload;
  },

}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
