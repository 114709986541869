////////////////////////////////
///////// actions
export const actionSettings = "actionSettings";
// currentAnimationName
export const currentAnimationName = "currentAnimationName";
export const isShowQuizRobotMain = "isShowQuizRobotMain";
// initial state
const state = () => ({
  [actionSettings]: [],
  [currentAnimationName]: {},
  [isShowQuizRobotMain]: true,
})

// getters
const getters = {
  [actionSettings](state) {
    return state[actionSettings];
  },
  [currentAnimationName](state) {
    return state[currentAnimationName];
  },
  [isShowQuizRobotMain](state) {
    return state[isShowQuizRobotMain];
  }

}

// actions
const actions = {
  [actionSettings](context, payload) {
    context.commit(actionSettings, payload);
  },
  [currentAnimationName](context, payload) {
    context.commit(currentAnimationName, payload);
  },
  [isShowQuizRobotMain](context, payload) {
    context.commit(isShowQuizRobotMain, payload);
  }

}

// mutations
const mutations = {
  reset: () => {},
  [actionSettings](state, payload) {
    state[actionSettings] = payload;

    console.log('set actionSettings', payload)
  },
  [currentAnimationName](state, payload) {
    state[currentAnimationName] = payload;

    console.log('set actionSettings', payload)
  },
  [isShowQuizRobotMain](state, payload) {
    state[isShowQuizRobotMain] = payload;

    console.log('set actionSettings', payload)
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
