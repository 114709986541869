import ApiService from "@/assets/js/api.service";
import {
  PLAYERS
} from "@/store/game";

export const FILE_S3_UPLOAD = "fileS3Upload";

// file upload
export const FILE_UPLOAD = "fileUpload";

const state = {}
const getters = {}
const mutations = {}

const actions = {
  [FILE_S3_UPLOAD](context, params) {
    // ! json 의 경우 data 만 보냄
    return new Promise(resolve => {
      ApiService.post('/api/s3/imageUpload', params).then(response => resolve(response))
    });
  },
  [FILE_UPLOAD](context, params) {
    return new Promise(resolve => {
      const formData = new FormData();
      formData.append('formData', params.selectedFile); // 'formData'는 multer의 upload.single() 메서드에 전달된 이름과 일치해야 합니다.

      ApiService.post('/api/s3/fileUpload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => resolve(response))
    });
  }
  /*[FILE_UPLOAD](context, params) {
    // ! json 의 경우 data 만 보냄
    return new Promise(resolve => {
      ApiService.post('/api/s3/fileUpload', params).then(response => resolve(response))
    });
  }*/
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
