////////////////////////////////
///////// actions
import {
  LOADING
} from "@/store/global";

export const swaInfo = "swaInfo";
export const artistName = "artistName";
export const isPositionRefresh = "isPositionRefresh";


// initial state
const state = () => ({
  [swaInfo]: {}, //
  [artistName]: {
    name: null,
  }, // artistName
  [isPositionRefresh]: false,
})

// getters
const getters = {
  [swaInfo](state) {
    return state[swaInfo];
  },
  [artistName](state) {
    return state[artistName];
  },
  [isPositionRefresh](state) {
    return state[isPositionRefresh];
  },
}

// actions
const actions = {
  [swaInfo](context, payload) {
    context.commit(swaInfo, payload);
  },
  [artistName](context, payload) {
    context.commit(artistName, payload);
  },
  [isPositionRefresh](context, payload) {
    context.commit(isPositionRefresh, payload);
  },
}

// mutations
const mutations = {
  reset: () => {},
  [swaInfo](state, payload) {
    state[swaInfo] = payload;
  },
  [artistName](state, payload) {
    state[artistName] = payload;
  },
  [isPositionRefresh](state, payload) {
    state[isPositionRefresh] = payload;
  },
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
