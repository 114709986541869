////////////////////////////////
///////// actions
export const LOADING = "LOADING ";
export const COUNTDOWN = "COUNTDOWN";
export const SECURITY = "SECURITY";
export const START = "START";
export const LOTTIE = "LOTTIE";
export const  TRANSLATE = "TRANSLATE";
export const  MODALNAME = "MODALNAME";
export const HYPHERMODALNAME = "HYPHERMODALNAME";
export const  LOCALENAME = "LOCALENAME";
export const ISCUBESOUND = "ISCUBESOUND"; // true 라고 소리 나오는게 아니라 값이 바뀌면 무조건 소리 나는거임
export const AUDIOPLAYER = "AUDIOPLAYER";
export const ARTISTPOPUP = "ARTISTPOPUP";
export const ISFLYMODE = "ISFLYMODE";
// initial state
const state = () => ({
  [LOADING]: {
    isLoading: false,
    isThreeLoading: false,
  },
  [COUNTDOWN]: {
    isCountDown: false
  },
  [SECURITY]: {
    isSecurity: false
  },
  [START]: {
    isStart: false
  },
  [LOTTIE]: {
    isLottie: false
  },
  [TRANSLATE]: {
    isEng: true,
    keyName: '',
  },
  [MODALNAME]: {
    modalName: 'default'
  },
  [HYPHERMODALNAME]: {
    modalName: 'default'
  },
  [LOCALENAME]: {
    localeName: 'asomeMeta'
  },
  [ISCUBESOUND]: {
    isCubeSound: false
  },
  [AUDIOPLAYER]: {
    isAudioMini: true
  },
  [ARTISTPOPUP]: {
    isArtistPopUp: true
  },
  [ISFLYMODE]: false,
})

// getters
const getters = {
  [LOADING](state) {
    return state[LOADING];
  },
  [COUNTDOWN](state) {
    return state[COUNTDOWN];
  },
  [SECURITY](state) {
    return state[SECURITY];
  },
  [START](state) {
    return state[START];
  },
  [LOTTIE](state) {
    return state[LOTTIE];
  },
  [TRANSLATE](state) {
    return state[TRANSLATE];
  },
  [MODALNAME](state) {
    return state[MODALNAME];
  },
  [HYPHERMODALNAME](state) {
    return state[HYPHERMODALNAME];
  },
  [LOCALENAME](state) {
    return state[LOCALENAME];
  },
  [ISCUBESOUND](state) {
    return state[ISCUBESOUND];
  },
  [AUDIOPLAYER](state) {
    return state[AUDIOPLAYER];
  },
  [ARTISTPOPUP](state) {
    return state[ARTISTPOPUP];
  },
  [ISFLYMODE](state) {
    return state[ISFLYMODE];
  }
}

// actions
const actions = {

  // payload : {isLoading : true/false}
  [LOADING](context, payload) {
    context.commit(LOADING, payload);
  },
  [COUNTDOWN](context, payload) {
    context.commit(COUNTDOWN, payload);
  },
  [SECURITY](context, payload) {
    context.commit(SECURITY, payload);
  },
  [START](context, payload) {
    context.commit(START, payload);
  },
  [LOTTIE](context, payload) {
    context.commit(LOTTIE, payload);
  },
  [TRANSLATE](context, payload) {
    context.commit(TRANSLATE, payload);
  },
  [MODALNAME](context, payload) {
    context.commit(MODALNAME, payload);
  },
  [HYPHERMODALNAME](context, payload) {
    context.commit(HYPHERMODALNAME, payload);
  },
  [LOCALENAME](context, payload) {
    context.commit(LOCALENAME, payload);
  },
  [ISCUBESOUND](context, payload) {
    context.commit(ISCUBESOUND, payload);
  },
  [AUDIOPLAYER](context, payload) {
    context.commit(AUDIOPLAYER, payload);
  },
  [ARTISTPOPUP](context, payload) {
    context.commit(ARTISTPOPUP, payload);
  },
  [ISFLYMODE](context, payload) {
    context.commit(ISFLYMODE, payload);
  },
}

// mutations
const mutations = {
  reset: () => {},

  [LOADING](state, payload) {
    state[LOADING] = payload;
  },
  [COUNTDOWN](state, payload) {
    state[COUNTDOWN] = payload;
  },
  [SECURITY](state, payload) {
    state[SECURITY] = payload;
  },
  [START](state, payload) {
    state[START] = payload;
  },
  [LOTTIE](state, payload) {
    state[LOTTIE] = payload;
  },
  [TRANSLATE](state, payload) {
    state[TRANSLATE] = payload;
  },
  [MODALNAME](state, payload) {
    state[MODALNAME] = payload;
  },
  [HYPHERMODALNAME](state, payload) {
    state[HYPHERMODALNAME] = payload;
  },
  [LOCALENAME](state, payload) {
    state[LOCALENAME] = payload;
  },
  [ISCUBESOUND](state, payload) {
    state[ISCUBESOUND] = payload;
  },
  [AUDIOPLAYER](state, payload) {
    state[AUDIOPLAYER] = payload;
  },
  [ARTISTPOPUP](state, payload) {
    state[ARTISTPOPUP] = payload;
  },
  [ISFLYMODE](state, payload) {
    state[ISFLYMODE] = payload;
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
