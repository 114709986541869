import {
  createRouter,
  createWebHistory
} from "vue-router";
/*import Preview from "../views/MainPreview.vue";*/
import NotFound from "../views/PageNotFound.vue";
// LOADING
import {
  useStore
} from "vuex";
import {
  LOADING
} from "@/store/global";
import {
  computed
} from "vue";

/** channelNames
 *
 * asomeMeta
 * metaCube
 * metaG
 *
 */
const routes = [{
  path: "/",
  name: "Main",
  component: () => import("../views/layout/DefaultLayout"),
  children: [
    /*{
            path: "",
            name: "world2",
            component: () => import("../views/components/World"),
          },*/
    /*{
      path: "/world",
      name: "world",
      component: () => import("../views/components/World"),
    },*/
    {
      path: "/startPage",
      name: "startPage",
      component: () => import("../views/components/StartPage"),
    },
    {
      path: "/sample",
      name: "sample",
      component: () => import("../views/components/Sample"),
    },
    {
      path: "/lounge",
      name: "lounge",
      component: () => import("../views/components/Lounge"),
    },
    {
      path: "/robotMain",
      name: "robotMain",
      component: () => import("../views/robotMain"),
      meta: {
        title: "Class",
      },
    },

    {
      path: "/chatRoom",
      name: "chatRoom",
      component: () => import("../views/components/ChatRoom"),
      meta: {
        title: "Class",
      },
    },
    {
      path: "/robotMain_test",
      name: "robotMain_test",
      component: () => import("../views/robotMain_test"),
      meta: {
        title: "Class",
      },
    },
    {
      path: "/robotMainSOS",
      name: "robotMainSOS",
      component: () => import("../views/robotMainSOS"),
      meta: {
        title: "Class",
      },
    },
    {
      path: "/robotMainSOS_new",
      name: "robotMainSOS_new",
      component: () => import("../views/robotMainSOS_new"),
      meta: {
        title: "Class",
      },
    },
    {
      path: "/member",
      name: "memberShip",
      component: () => import("../views/components/MemberShip"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/components/Login"),
    },
    {
      path: "/phoneAuth",
      name: "phoneAuth",
      component: () => import("../views/components/PhoneAuth"),
    },
    {
      path: "/coursePayment",
      name: "coursePayment",
      component: () => import("../views/components/CoursePayment"),
    },
    {
      path: "/studentAndClassManagement",
      name: "studentAndClassManagement",
      component: () => import("../views/components/StudentAndClassManagement"),
    },
    {
      path: "/awesomeAbout",
      name: "awesomeAbout",
      component: () => import("../views/components/AwesomeAbout"),
    },
    {
      path: "/game",
      name: "game",
      component: () => import("../views/components/Game"),
    },
    {
      path: "/myPage",
      name: "myPage",
      component: () => import("../views/components/MyPage"),
    },
    {
      path: "/myPage2",
      name: "myPage2",
      component: () => import("../views/components/MyPage_2"),
    },
    {
      path: "/adminPage",
      name: "adminPage",
      component: () => import("../views/components/AdminPage"),
    },
    {
      path: "/nftPage",
      name: "nftPage",
      component: () => import("../views/components/NFTPage"),
    },
    {
      path: "/myPageStatus",
      name: "myPageStatus",
      component: () => import("../views/components/MyPageStatus"),
    },
    {
      path: "/aboutAsomeIT",
      name: "aboutAsomeIT",
      component: () => import("../views/components/AboutAsomeIT"),
    },
    {
      path: "/codingClass",
      name: "codingClass",
      component: () => import("../views/components/CodingClass"),
    },
    {
      path: "/tutorialSlide",
      name: "tutorialSlide",
      component: () => import("../views/components/TutorialSlide"),
    },
    {
      path: "/gameTutorial",
      name: "gameTutorial",
      component: () => import("../views/components/GameTutorial"),
    },
    {
      path: "/gameList",
      name: "gameList",
      component: () => import("../views/components/GameList"),
    },
    {
      path: "/pageNav",
      name: "pageNav",
      component: () => import("../views/components/PageNav"),
    },
    {
      path: "/loading",
      name: "loading",
      component: () => import("../views/components/Loading"),
    },
    {
      path: "/start",
      name: "start",
      component: () => import("../views/components/Start"),
    },
    {
      path: "/countDown",
      name: "countDown",
      component: () => import("../views/components/CountDown"),
    },
    {
      path: "/securityPopup",
      name: "securityPopup",
      component: () => import("../views/components/SecurityPopup"),
    },
    {
      path: "/courseApply",
      name: "courseApply",
      component: () => import("../views/components/CourseApply"),
    },
    {
      path: "/courseList",
      name: "courseList",
      component: () => import("../views/components/CourseList"),
    },
    {
      path: "/quiz",
      name: "quiz",
      component: () => import("../views/components/Quiz"),
    },
    {
      path: "/asomeIT",
      name: "asomeIT",
      component: () => import("../views/components/AsomeIT"),
    },
    {
      path: "/authPassword",
      name: "authPassword",
      component: () => import("../views/components/AuthPassword"),
    },
    {
      path: "/quizRobotMain",
      name: "quizRobotMain",
      component: () => import("../views/components/QuizRobotMain"),
    },
    {
      path: "/certificate",
      name: "certificate",
      component: () => import("../views/components/Certificate"),
    },
    {
      path: "/setupTutorial",
      name: "setupTutorial",
      component: () => import("../views/components/SetupTutorial"),
    },
    {
      path: "/myButton",
      name: "myButton",
      component: () => import("../views/components/MyButton"),
    },
    {
      path: "",
      name: "metaCubeMain2",
      component: () => import("../views/components/MetaCubeMain"),
    },
    {
      path: "/metaCubeMain",
      name: "metaCubeMain",
      component: () => import("../views/components/MetaCubeMain"),
    },
    {
      path: "/hypherMixDetail",
      name: "hypherMixDetail",
      component: () => import("../views/components/HypherMixDetail"),
    },
    {
      path: "/metaCubeLounge",
      name: "metaCubeLounge",
      component: () => import("../views/components/MetaCubeLounge"),
    },
    {
      path: "/metaCubeLAB",
      name: "metaCubeLAB",
      component: () => import("../views/components/MetaCubeLAB"),
    },
    {
      path: "/metaCubeLounge",
      name: "metaCubeLounge",
      component: () => import("../views/components/MetaCubeLounge"),
    },
    {
      path: "/metaGShowRoom",
      name: "metaGShowRoom",
      component: () => import("../views/components/MetaGShowRoom"),
    },
    {
      path: "/ybleShowRoom",
      name: "ybleShowRoom",
      component: () => import("../views/components/YbleShowRoom"),
    },
    {
      path: "/asomeBottomThreeBtn",
      name: "asomeBottomThreeBtn",
      component: () => import("../views/components/AsomeBottomThreeBtn"),
    },
    {
      path: "/swiperCarousel",
      name: "swiperCarousel",
      component: () => import("../components/SwiperCarousel"),
    },
    {
      path: "/webRtcQr",
      name: "webRtcQr",
      component: () => import("../views/components/WebRTCQR"),
    },
    {
      path: "/asomeMetaCubeShowRoom",
      name: "asomeMetaCubeShowRoom",
      component: () => import("../views/components/AsomeMetaCubeShowRoom.vue"),
    },
    {
      path: "/metaCubeShowRoom",
      name: "metaCubeShowRoom",
      component: () => import("../views/components/MetaCubeShowRoom"),
    },
    {
      path: "/bTheHCShowRoom",
      name: "bTheHCShowRoom",
      component: () => import("../views/components/BTheHCShowRoom"),
    },
    {
      path: "/metaCubeGallery",
      name: "metaCubeGallery",
      component: () => import("../views/components/MetaCubeGallery"),
    },
    {
      path: "/metaCubeExpo",
      name: "metaCubeExpo",
      component: () => import("../views/components/MetaCubeExpo"),
    },
    {
      path: "/metaCubeStage",
      name: "metaCubeStage",
      component: () => import("../views/components/MetaCubeStage"),
    },
    {
      path: "/asomeBottomBtn",
      name: "asomeBottomBtn",
      component: () => import("../views/components/AsomeBottomBtn"),
    },
    {
      path: "/classApplication",
      name: "classApplication",
      component: () => import("../views/components/ClassApplication"),
    },
    {
      path: "/classApproval",
      name: "classApproval",
      component: () => import("../views/components/ClassApproval"),
    },
    {
      path: "/serviceSubscribe",
      name: "serviceSubscribe",
      component: () => import("../views/components/ServiceSubscribe"),
    },
    {
      path: "/market",
      name: "market",
      component: () => import("../views/components/Market"),
    },
    {
      path: "/marketRyoko",
      name: "marketRyoko",
      component: () => import("../views/components/MarketRyoko"),
    },
    {
      path: "/marketDiscover",
      name: "marketDiscover",
      component: () => import("../views/components/MarketDiscover"),
    },
    {
      path: "/asomeMarket",
      name: "asomeMarket",
      component: () => import("../views/components/AsomeMarket"),
    },
    {
      path: "/asomeMarketRyoko",
      name: "asomeMarketRyoko",
      component: () => import("../views/components/AsomeMarketRyoko"),
    },
    {
      path: "/asomeMarketDiscover",
      name: "asomeMarketDiscover",
      component: () => import("../views/components/AsomeMarketDiscover"),
    },
    {
      path: "/metaCubeMyPage",
      name: "metaCubeMyPage",
      component: () => import("../views/components/MetaCubeMyPage"),
    },
    {
      path: "/aframePage",
      name: "aframePage",
      component: () => import("../views/components/AframePage"),
    },
    {
      path: "/speakingPage",
      name: "speakingPage",
      component: () => import("../views/components/SpeakingPage"),
    },
    {
      path: "/userAuth",
      name: "userAuth",
      component: () => import("../views/components/UserAuth"),
    },
    {
      path: "/asomeMetaMyPage",
      name: "asomeMetaMyPage",
      component: () => import("../views/components/AsomeMetaMyPage"),
    },
    {
      path: "/land",
      name: "land",
      component: () => import("../views/components/Land"),
    },
    {
      path: "/asomeStreaming",
      name: "asomeStreaming",
      component: () => import("../views/components/AsomeStreaming"),
    },
    {
      path: "/metaCubeToken",
      name: "metaCubeToken",
      component: () => import("../views/components/MetaCubeToken"),
    },
    {
      path: "/asomeMetaLobby",
      name: "asomeMetaLobby",
      component: () => import("../views/components/AsomeMetaLobby"),
    },
    {
      path: "/aniBearLobby",
      name: "aniBearLobby",
      component: () => import("../views/components/AniBearLobby"),
    },
    {
      path: "/lobbyPanel",
      name: "lobbyPanel",
      component: () => import("@/components/lobbyPanel"),
    },
    {
      path: "/threeLoading",
      name: "threeLoading",
      component: () => import("../views/components/ThreeLoading"),
    },
    {
      path: "/chatBotPopUp",
      name: "chatBotPopUp",
      component: () => import("../views/components/ChatBotPopUp"),
    },
    {
      path: "/nadLobby",
      name: "nadLobby",
      component: () => import("../views/components/NADLobby"),
    },
    {
      path: "/nadLobbyPanel",
      name: "nadLobbyPanel",
      component: () => import("../components/NadLobbyPanel"),
    },
    {
      path: "/greenAppleLobby",
      name: "greenAppleLobby",
      component: () => import("../views/components/GreenAppleLobby"),
    },
    {
      path: "/dscLobby",
      name: "dscLobby",
      component: () => import("../views/components/DSCLobby"),
    },
    {
      path: "/idleLobby",
      name: "idleLobby",
      component: () => import("../views/components/IDLELobby"),
    },
    {
      path: "/artistProfile",
      name: "artistProfile",
      component: () => import("../views/components/ArtistProfile"),
    },
    {
      path: "/cookiePage",
      name: "cookiePage",
      component: () => import("../views/components/CookiePage"),
    },
  ],
  meta: {
    // title: "METACUBE",
    title: "HYPHERVERSE",
  },
},
  {
    path: "/GeckoTest",
    name: "GeckoTest",
    component: () => import("../views/GeckoTest"),
    meta: {
      title: "GeckoTest",
    },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test"),
    meta: {
      title: "test",
    },
  },
  {
    path: "/audioPlayer",
    name: "audioPlayer",
    component: () => import("../views/components/AudioPlayer"),
    meta: {
      title: "audioPlayer",
    },
  },
  /*{
      path: "/canvas",
      name: "DrawingCanvas",
      component: () => import("../views/DrawingCanvas"),
      meta: {
          title: "Home Dark || Tunis VueJS 3 Personal Portfolio Template",
      },
  },*/
  /*
  {
    path: "/home-dark",
    name: "HomeDark",
    component: () => import("../views/MainPage.vue"),
    meta: {
      title: "Home Dark || Tunis VueJS 3 Personal Portfolio Template",
    },
  },
  {
    path: "/home-light",
    name: "HomeLight",
    component: () => import("../views/HomeLight.vue"),
    meta: {
      title: "Home Light || Tunis VueJS 3 Personal Portfolio Template",
    },
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("../views/WelcomePage.vue"),
    meta: {
      title: "Home Light || welcomePage",
    },
  },*/
  {
    path: "/:catchAll(.*)",
    component: NotFound,
    meta: {
      title: "Not Found",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
  window.scrollTo(0, 0);

});

const isDiv = computed(() => {
  return process.env.NODE_ENV === 'development' ? true : false;
});

export default router;
