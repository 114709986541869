import axios from "axios";
import VueAxios from "vue-axios";
import {
  mapGetters,
  useStore
} from "vuex";
import {
  LOADING
}

from "@/store/global";

const ApiService = {
  vueInstance: null,
  init(app, store) {
    // set axios base url
    // axios.defaults.baseURL = "http://localhost:8000";
    // add cors
    // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    app.use(VueAxios, axios);

    this.vueInstance = app;
    this.store = store;
  },

  async query(resource, params) {
    if (params.isLoading != false) {
      this.store.dispatch(LOADING, {
        isLoading: true
      });
    }
    let res;
    try {

      res = await this.vueInstance.axios.get(resource, {
        params: params
      })

    } catch (e) {
      console.error(e);
      return null;
    } finally {
      this.store.dispatch(LOADING, {
        isLoading: false
      });
    }

    return res;
  },
  async get(resource, slug = "") {
    // if (params.isLoading != false) {
    this.store.dispatch(LOADING, {
      isLoading: true
    });
    // }
    let res;
    try {

      res = await this.vueInstance.axios.get(resource, {
        // params: params
      })

    } catch (e) {
      console.error(e);
      return null;
    } finally {
      this.store.dispatch(LOADING, {
        isLoading: false
      });
    }

    return res;
  },
  async post(resource, params) {
    /*if (params.isLoading != false) {
      this.store.dispatch(LOADING, {
        isLoading: true
      });
    }*/

    let res;
    try {

      res = await this.vueInstance.axios.post(`${resource}`, params);
      console.log('post =====>', resource, params, res)
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      this.store.dispatch(LOADING, {
        isLoading: false
      });
    }

    return res;
  }
}

axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default ApiService;
