////////////////////////////////
///////// actions
import {USER_INFO} from "@/store/user";

export const QUIZZES = "QUIZZES";
export const IS_QUIZ_END = "IS_QUIZ_END";
export const KEEP_GOING_CLICK = "KEEP_GOING_CLICK";
export const SOS_CLICK = "SOS_CLICK";

// initial state
const state = () => ({
    [QUIZZES]: { },
    [IS_QUIZ_END]: false,
    [KEEP_GOING_CLICK]: false,
    [SOS_CLICK]: false
})

// getters
const getters = {

    [QUIZZES](state) {
        return state[QUIZZES];
    },

    [IS_QUIZ_END](state) {
        return state[IS_QUIZ_END];
    },

    [KEEP_GOING_CLICK](state) {
        return state[KEEP_GOING_CLICK];
    },

    [SOS_CLICK](state) {
        return state[SOS_CLICK];
    },

}

// actions
const actions = {
    [QUIZZES](context, payload) {
        context.commit(QUIZZES, payload);
        console.log('set QUIZZES', payload)
    },
    [IS_QUIZ_END](context, payload) {
        context.commit(IS_QUIZ_END, payload);
        console.log('set isQuizEnd', payload)
    },
    [KEEP_GOING_CLICK](context, payload) {
        context.commit(KEEP_GOING_CLICK, payload);
        console.log('set keepgoingClick', payload)
    },
    [SOS_CLICK](context, payload) {
        context.commit(SOS_CLICK, payload);
        console.log('set SoSClick', payload)
    }
}

// mutations
const mutations = {
    reset: () => {},
    [QUIZZES](state, payload) {
        state[QUIZZES] = payload;

        console.log('set QUIZZES', payload)
    },
    [IS_QUIZ_END](state, payload) {
        state[IS_QUIZ_END] = payload;

        console.log('set isQuizEnd', payload)
    },
    [KEEP_GOING_CLICK](state, payload) {
        state[KEEP_GOING_CLICK] = payload;

        console.log('set keepgoingClick', payload)
    },
    [SOS_CLICK](state, payload) {
        state[SOS_CLICK] = payload;

        console.log('set SoSClick', payload)
    }
}
export default {
    namespaced: false,
    state,
    getters,
    actions,
    mutations,
}
